import React, { useState } from 'react';

const WelcomePage = ({ onStart }) => {
  // Manage the agreedToTerms state locally within the component
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  return (
    <div className="bg-gray-900 bg-opacity-100 p-10 rounded-3xl shadow-2xl text-center max-w-xl mx-auto border border-gray-700 backdrop-blur-lg">
      <img src="dog-head.png" alt="ACFT Coach Logo" className="w-48 mx-auto mb-4" />
      <h2 className="text-4xl font-medium mb-6 text-gray-200 tracking-widest">Welcome to ACFT Coach!</h2>
      <p className="text-lg mb-8 text-gray-400">AI for Improving Army Combat Fitness Test Readiness</p>
      <label className="flex items-center justify-center mb-8 text-gray-400">
        <input
          type="checkbox"
          checked={agreedToTerms}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
          className="mr-3 form-checkbox h-5 w-5 text-blue-500 rounded-lg focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
        <span className="text-sm">I understand this is a prototype demonstration</span>
      </label>
      <button 
        onClick={() => {
          if (agreedToTerms) {
            onStart(); // Trigger the start only when terms are agreed to
          }
        }}
        disabled={!agreedToTerms}
        className={`px-8 py-3 rounded-full text-lg font-bold shadow-lg transform transition-transform duration-300 ease-out ${agreedToTerms ? 'bg-gradient-to-r from-blue-500 to-teal-400 text-white hover:scale-105 hover:from-blue-600 hover:to-teal-500' : 'bg-gray-700 text-gray-500 cursor-not-allowed'}`}
      >
        Start Your Fitness Journey
      </button>
    </div>
  );
};

export default WelcomePage;
