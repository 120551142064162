import React, { useState } from 'react';
import { Loader, MessageCircle, CheckCircle, Send } from 'lucide-react';

const WorkoutPlanDisplay = ({ plan, onFinish, onRegenerate, feedbackAttempts, isLoading }) => {
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedback, setFeedback] = useState('');

  const handleFeedbackSubmit = () => {
    onRegenerate(feedback);
    setShowFeedbackInput(false);
    setFeedback(''); // Reset feedback input
  };

  return (
    <div className="bg-gray-900 p-8 rounded-lg shadow-2xl max-w-2xl mx-auto border border-gray-700">
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader className="animate-spin text-gray-300" size={48} />
          <p className="text-gray-300 mt-4">Regenerating your workout plan...</p>
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold mb-6 text-gray-200">Review Your Personalized Plan</h2>

          {/* Progress Indicator */}
          <div className="mb-6">
            <div className="w-full bg-gray-600 rounded-full h-2.5">
              <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '100%' }}></div>
            </div>
            <p className="text-sm text-gray-400 mt-2">Step 3 of 3</p>
          </div>

          <div className="mb-6 text-gray-300 whitespace-pre-wrap">
            {plan}
          </div>
          <div className="flex justify-between space-x-4">
            {feedbackAttempts < 2 && (
              <button 
                onClick={() => setShowFeedbackInput(true)}
                className="flex-1 bg-orange-500 text-white py-3 rounded-md hover:bg-orange-400 transition duration-300 flex items-center justify-center"
              >
                <MessageCircle className="mr-2" size={20} />
                I Have Comments
              </button>
            )}
            <button 
              onClick={onFinish}
              className="flex-1 bg-green-600 text-white py-3 rounded-md hover:bg-green-500 transition duration-300 flex items-center justify-center"
            >
              <CheckCircle className="mr-2" size={20} />
              Finish and Export
            </button>
          </div>

          {showFeedbackInput && (
            <div className="mt-6">
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Please provide your feedback here..."
                className="w-full p-4 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              />
              <button 
                onClick={handleFeedbackSubmit}
                className="bg-green-600 text-white px-4 py-2 rounded-full shadow-lg hover:bg-green-500 transition duration-300 mt-4 flex items-center justify-center"
              >
                <Send className="mr-2" size={20} />
                Submit Feedback
              </button>
            </div>
          )}

          {feedbackAttempts >= 2 && (
            <p className="mt-6 text-gray-500">You've reached the maximum number of feedback attempts.</p>
          )}
        </>
      )}
    </div>
  );
};

export default WorkoutPlanDisplay;
