import React from 'react';
import { ArrowRight, Loader } from 'lucide-react';

const ScoreInput = ({ scores, handleInputChange, onNext, isLoading, error }) => {
  const validateScores = () => {
    for (let key in scores) {
      const value = parseInt(scores[key], 10);
      if (isNaN(value) || value < 0 || value > 100) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="bg-gray-900 p-8 rounded-lg shadow-2xl max-w-2xl mx-auto border border-gray-700">
      <h2 className="text-2xl font-bold mb-6 text-gray-200">Enter Your ACFT Scores (0-100)</h2>

      {/* Progress Indicator */}
      <div className="mb-6">
        <div className="w-full bg-gray-600 rounded-full h-2.5">
          <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '66%' }}></div>
        </div>
        <p className="text-sm text-gray-400 mt-2">Step 2 of 3</p>
      </div>

      <div className="space-y-4">
        {Object.entries(scores).map(([key, value]) => (
          <input
            key={key}
            type="number"
            name={key}
            placeholder={`${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')} (0-100)`}
            value={value}
            onChange={handleInputChange}
            min="0"
            max="100"
            className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        ))}
        {error && <p className="text-red-500">{error}</p>}
        <button
          onClick={() => {
            if (validateScores()) {
              onNext();
            } else {
              alert('Please enter valid scores between 0 and 100 for all fields.');
            }
          }}
          disabled={isLoading}
          className={`px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-500 flex items-center justify-center text-lg font-semibold shadow-lg transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? (
            <>
              Generating Plan <Loader className="ml-2 animate-spin" size={24} />
            </>
          ) : (
            <>
              Generate Workout Plan <ArrowRight className="ml-2" size={24} />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default ScoreInput;
