import React, { useState, useCallback, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import WelcomePage from './WelcomePage';
import UserDetailsInput from './UserDetailsInput';
import ScoreInput from './ScoreInput';
import WorkoutPlanDisplay from './WorkoutPlanDisplay';
import FinalizePlan from './FinalizePlan';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const ACFTCoach = () => {
  const [step, setStep] = useState(0); // Tracks which step the user is on
  const [userDetails, setUserDetails] = useState({
    age: '',
    weight: '',
    height: '',
    gender: ''
  });
  const [scores, setScores] = useState({
    deadlift: '',
    standingPowerThrow: '',
    pushups: '',
    sprintDragCarry: '',
    plank: '',
    twoMileRun: ''
  });
  const [workoutPlan, setWorkoutPlan] = useState('');
  const [structuredWorkoutPlan, setStructuredWorkoutPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedbackAttempts, setFeedbackAttempts] = useState(0);

  useEffect(() => {
    // Reset error when step changes
    setError(null);
  }, [step]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setUserDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  }, []);

  const handleScoresChange = useCallback((event) => {
    const { name, value } = event.target;
    setScores(prevScores => ({ ...prevScores, [name]: value }));
  }, []);

  const callBackendAPI = useCallback(async (endpoint, data) => {
    const url = `${API_BASE_URL}/${endpoint}`;
    console.log('Calling API:', url);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      console.log('Response status: ', response.status);
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `HTTP error!! status: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error(`Error calling backend API: ${error.message}`);
      throw error;
    }
  }, []);

  const parseWorkoutPlanToJSON = useCallback(async (workoutPlanText) => {
    try {
      const response = await callBackendAPI('parse-workout', { workoutPlanText });
      return response.structuredData;
    } catch (error) {
      console.error('Error parsing workout plan to JSON:', error);
      setError('Failed to parse workout plan. Please try again!');
      return null;
    }
  }, [callBackendAPI]);

  const generateWorkoutPlan = useCallback(async (feedback = '') => {
    console.log('Generating workout plan...');
    setIsLoading(true);
    setError(null);

    try {
      const response = await callBackendAPI('generate-workout', {
        userDetails,
        scores,
        feedback,
      });

      if (response.workoutPlan) {
        setWorkoutPlan(response.workoutPlan);

        // Parse the workout plan into JSON structure
        const structuredData = await parseWorkoutPlanToJSON(response.workoutPlan);
        setStructuredWorkoutPlan(structuredData);

        setStep(3); // Move to the workout plan display step
      } else {
        throw new Error('API response does not contain expected workout plan content');
      }
    } catch (error) {
      console.error('Oops -- error generating workout plan:', error);
      setError(`Failed to generate workout plan: ${error.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  }, [userDetails, scores, callBackendAPI, parseWorkoutPlanToJSON]);

  const handleRegenerate = useCallback((feedback) => {
    if (feedbackAttempts < 2) {
      setFeedbackAttempts(prev => prev + 1);
      generateWorkoutPlan(feedback);
    } else {
      setError("Maximum feedback attempts reached. Please start over with new inputs.");
    }
  }, [feedbackAttempts, generateWorkoutPlan]);

  const handleFinish = useCallback(() => {
    setStep(4); // Move to the finalize plan step
  }, []);

  const renderStep = () => {
    switch(step) {
      case 0:
        return (
          <WelcomePage
            onStart={() => setStep(1)} // Move to the next step when terms are accepted
          />
        );
      case 1:
        return (
          <UserDetailsInput
            userDetails={userDetails}
            handleInputChange={handleInputChange}
            onNext={() => setStep(2)}
          />
        );
      case 2:
        return (
          <ScoreInput
            scores={scores}
            handleInputChange={handleScoresChange}
            onNext={generateWorkoutPlan}
            isLoading={isLoading}
            error={error}
          />
        );
      case 3:
        return (
          <WorkoutPlanDisplay
            plan={workoutPlan}
            structuredPlan={structuredWorkoutPlan}
            onFinish={handleFinish}
            onRegenerate={handleRegenerate}
            feedbackAttempts={feedbackAttempts}
            isLoading={isLoading}
            error={error}
          />
        );
      case 4:
        return (
          <FinalizePlan 
            plan={workoutPlan} 
            structuredWorkoutPlan={structuredWorkoutPlan} 
          />
        );
      default:
        return <div className="text-center text-xl text-gray-300">An error occurred. Please refresh the page.</div>;
    }
  };

  return (
    <div className="min-h-screen bg-black bg-opacity-100 flex flex-col font-sans">
      <Header />
      <main className="container mx-auto px-4 py-8 flex-grow">
        {renderStep()}
        {error && (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ACFTCoach;
