import React from 'react';
import { ArrowRight } from 'lucide-react';

const UserDetailsInput = ({ userDetails, handleInputChange, onNext }) => (
  <div className="bg-gray-900 p-8 rounded-lg shadow-2xl max-w-2xl mx-auto border border-gray-700">
    <h2 className="text-2xl font-bold mb-6 text-gray-200">Enter Your Details</h2>
    <p className="mb-4 text-gray-400">
      We don't save this information, it's completely optional, and only used to tailor recommendations.
    </p>

    {/* Progress Indicator */}
    <div className="mb-6">
      <div className="w-full bg-gray-600 rounded-full h-2.5">
        <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '33%' }}></div>
      </div>
      <p className="text-sm text-gray-400 mt-2">Step 1 of 3</p>
    </div>

    <div className="space-y-4">
      <input
        type="number"
        name="age"
        placeholder="Age (years)"
        value={userDetails.age}
        onChange={handleInputChange}
        min="0"
        className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
      />
      <input
        type="number"
        name="weight"
        placeholder="Weight (lbs)"
        value={userDetails.weight}
        onChange={handleInputChange}
        min="0"
        className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
      />

      {/* Height Input */}
      <div className="flex space-x-4">
        <input
          type="number"
          name="heightFeet"
          placeholder="Height (ft)"
          value={userDetails.heightFeet || ''}
          onChange={handleInputChange}
          min="0"
          className="w-1/2 px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
        <input
          type="number"
          name="heightInches"
          placeholder="Height (in)"
          value={userDetails.heightInches || ''}
          onChange={handleInputChange}
          min="0"
          className="w-1/2 px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
      </div>

      <select
        name="gender"
        value={userDetails.gender}
        onChange={handleInputChange}
        className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-lg text-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
      >
        <option value="" disabled>Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
        <option value="preferNotToSay">Prefer Not to Say</option>
      </select>

      {/* Continue Button */}
      <button
        onClick={onNext}
        className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-500 flex items-center justify-center text-lg font-semibold shadow-lg transition duration-300"
      >
        Continue <ArrowRight className="ml-2" size={24} />
      </button>
    </div>
  </div>
);

export default UserDetailsInput;
