import React from 'react';
import { Clipboard, Mail, Calendar, ArrowLeftCircle } from 'lucide-react';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const FinalizePlan = ({ plan, structuredWorkoutPlan, onRestart }) => {

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(plan);
    alert('Workout plan copied to clipboard!');
  };

  const handleStartEmail = () => {
    const mailtoLink = `mailto:?subject=My Workout Plan&body=${encodeURIComponent(plan)}`;
    window.location.href = mailtoLink;
  };

  const generateICalForWeek = () => {
    let icalContent = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Your App//Workout Plan//EN\n`;

    daysOfWeek.forEach((day, index) => {
      if (structuredWorkoutPlan && structuredWorkoutPlan[day]) {
        const event = structuredWorkoutPlan[day];
        const start = new Date();
        start.setDate(start.getDate() + ((index + 1) - start.getDay() + 7) % 7);
        start.setHours(7, 0, 0, 0); // Set to 7 AM

        const startDate = start.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
        start.setHours(8, 0, 0, 0); // End time 1 hour later
        const endDate = start.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';

        icalContent += `BEGIN:VEVENT\nUID:${Date.now() + index}\nDTSTAMP:${startDate}\nDTSTART:${startDate}\nDTEND:${endDate}\nSUMMARY:${event.title}\nDESCRIPTION:${event.details}\nRRULE:FREQ=WEEKLY\nEND:VEVENT\n`;
      }
    });

    icalContent += `END:VCALENDAR`;

    const blob = new Blob([icalContent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'weekly-workout-plan.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  const handleBackToStart = () => {
    // Redirect to acftcoach.com
    window.location.href = 'https://acftcoach.com';
  };

  return (
    <div className="bg-gray-900 p-10 rounded-3xl shadow-2xl max-w-3xl mx-auto border border-gray-700 backdrop-blur-lg">
      <h2 className="text-3xl font-bold mb-8 text-gray-200 text-center">Finalize Your Workout Plan</h2>
      <div className="space-y-6">
        <button 
          onClick={handleCopyToClipboard}
          className="w-full bg-gradient-to-r from-blue-500 to-green-400 text-white py-4 rounded-lg hover:from-blue-600 hover:to-green-500 flex items-center justify-center text-lg font-semibold shadow-lg transition duration-300 transform hover:scale-105"
        >
          <Clipboard className="mr-2" size={24} />
          Copy to Clipboard
        </button>
        <button 
          onClick={handleStartEmail}
          className="w-full bg-gradient-to-r from-purple-500 to-pink-400 text-white py-4 rounded-lg hover:from-purple-600 hover:to-pink-500 flex items-center justify-center text-lg font-semibold shadow-lg transition duration-300 transform hover:scale-105"
        >
          <Mail className="mr-2" size={24} />
          Send via Email
        </button>
        <button 
          onClick={generateICalForWeek}
          className="w-full bg-gradient-to-r from-yellow-500 to-orange-400 text-white py-4 rounded-lg hover:from-yellow-600 hover:to-orange-500 flex items-center justify-center text-lg font-semibold shadow-lg transition duration-300 transform hover:scale-105"
        >
          <Calendar className="mr-2" size={24} />
          Add to Weekly Calendar
        </button>
        <button 
          onClick={handleBackToStart}
          className="w-full text-gray-200 py-4 rounded-lg flex items-center justify-center text-lg font-medium transition duration-300 hover:text-blue-500"
        >
          <ArrowLeftCircle className="mr-2" size={24} />
          Back to Start
        </button>
      </div>
    </div>
  );
};

export default FinalizePlan;
