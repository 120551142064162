import React from 'react';
import { Share2 } from 'lucide-react';

const Header = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'ACFT Coach',
        text: 'Check out ACFT Coach for personalized fitness plans!',
        url: 'https://acftcoach.com',
      });
    } else {
      alert('Share feature is not supported in your browser.');
    }
  };

  return (
    <header className="bg-black py-6 shadow-md backdrop-blur-lg">
  <div className="container mx-auto flex flex-col items-center">
    <a href="https://acftcoach.com" className="flex items-center text-gray-200 hover:text-gray-300 transition duration-300">
      {/* <img src="/dog-head.png" alt="ACFT Coach Logo" className="h-12 mr-2" /> */}
      <span className="text-4xl font-medium">ACFT Coach</span>
    </a>
    <button 
      onClick={handleShare}
      className="mt-2 flex items-center text-gray-200 hover:text-blue-500 transition duration-300 text-sm"
      title="Share ACFT Coach"
    >
      <Share2 className="mr-1" size={18} />
      <span>Share App</span>
    </button>
  </div>
</header>

  );
};

export default Header;
